<template>
    <!-- 会员服务 -->
    <div class="introduce">
        <topHeader ind="5"></topHeader>
        <div class="intro_content">
            <el-tabs tab-position="left" v-model="activeName">
                <el-tab-pane label="入会须知" name="notice">
                    <div class="tab_nav">首页 > <span>会员服务</span></div>
                    <div class="tab_text">
                        <div class="title">一、学会简介</div>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;河北省增材制造学会是由河北科技大学、河钢集团有限公司、华北理工大学、石家庄铁道大学、中航迈特粉冶科技（固安）有限公司联合发起成立，由从事增材制造技术研究与应用的个人、企事业单位和社会组织自愿结成的地方性学术团体和非营利性社会组织，是为促进河北省战略性新兴产业的繁荣和发展，促进河北省增材制造技术的普及和推广，促进增材制造技术人才的成长和提高，促进增材制造技术更好地服务我省经济，而打造的一个增材制造理论、技术与应用研究，增材制造学术交流与普及，增材制造咨询服务与政策建议平台。学会接受业务主管单位河北省科学技术协会与社团登记管理机关河北省民政厅的业务指导和监督管理。</div>
                        <div class="title">二、业务范围</div>
                        <div>
                            <div>1、开展国内外增材制造及其相关学科的科学研究、学术交流，科学普及、科技推广与咨询服务，促进科技发展；</div>
                            <div>2、开展增材制造及其相关学科的科学论证、培训工作，提出政策建议，促进科学技术成果的转化；</div>
                            <div>3、经国家相关部门的批准，制定教育教学学科、专业标准，研究并编写增材制造相关教材，出版相关学术性书刊；</div>
                            <div> 4、反映科研工作者的建议、意见和诉求，维护科研工作者的合法权益。</div>
                        </div>
                        <div class="title">三、会员享有权利</div>
                        <div>
                            <div>1、本学会的选举权、被选举权和表决权；</div>
                            <div>2、对本学会工作的批评建议权和监督权；</div>
                            <div>3、参加本学会的活动，获得本学会服务的优先权；</div>
                            <div>4、入会自愿，退会自由。</div>
                        </div>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;诚挚地邀请从事增材制造技术和设备研究、增材制造市场和应用开发的相关企事业单位或个人，以及对增材制造技术感兴趣的单位或个人加入到河北省增材制造学会大家庭！</div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="入会程序说明" name="description">
                    <div class="tab_nav">首页 > <span>会员服务</span></div>
                    <div class="tab_text">
                        <div class="title">1.填写入会申请</div>
                        <div class="title">2.提交材料：</div>
                        <div class="submit">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;①单位入会：单位会员入会申请（需注明：单位名称（加盖公章）、法定代表人、联系人、联系电话、单位地址）。</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;②个人入会：个人会员入会申请（需注明：姓名、性别、政治面貌、工作单位、职务/职称、联系电话、本人签字）。</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;③入会申请材料准备好后，请在学会官网“会员服务”→“申请入会”栏目中填写相关信息，并上传电子版申请材料，学会秘书处将进行审核。</div>
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;④申请材料提交后，请添加学会秘书处联系人微信（18010005590），方便邀请您加入学会交流群。</div>
                        </div>
                        <!-- <div class="title">3.收到资料后，理事会按章程审批会员资格</div> -->
                        <div class="submit">
                            <div><i></i><a href="./单位会员入会申请.docx" download="单位会员入会申请.docx">附件1：单位会员入会申请</a></div>
                            <div><i></i><a href="./个人会员入会申请.docx" download="个人会员入会申请.docx">附件2：个人会员入会申请</a></div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="申请入会" name="apply">
                    <div class="tab_nav">首页 > <span>会员服务</span></div>
                    <div class="tab_text">
                        <div class="login_con">
                            <div>
                                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
									<el-form-item prop="type">
    								    <el-select v-model="ruleForm.type" placeholder="会员类型">
											<i slot="prefix" class="vip_type"></i>
											<el-option
											v-for="item in options"
											:key="item.value"
											:value="item.value">
											</el-option>
										</el-select>
  								    </el-form-item>
									<el-form-item v-if="this.ruleForm.type == '单位会员'" 
										prop="name"
										:rules="[
											{ required:true , message:'请输入单位名称', trigger: 'blur' }
										]"
									>
    								    <el-input type="text" v-model="ruleForm.name" placeholder='单位名称' autocomplete="off">
										    <i slot="prefix" class="unitname_png"></i>
									    </el-input>
  								    </el-form-item>
  								    <el-form-item v-if="this.ruleForm.type == '个人会员'" 
										prop="name"
										:rules="[
											{ required:true , message:'请输入姓名', trigger: 'blur' }
										]"
									>
    								    <el-input type="text" v-model="ruleForm.name" placeholder='姓名' autocomplete="off">
										    <i slot="prefix" class="name_png"></i>
									    </el-input>
  								    </el-form-item>
									  <el-form-item prop="mobile">
    								    <el-input type="text" v-model="ruleForm.mobile" placeholder='手机号' autocomplete="off">
										    <i slot="prefix" class="phone_png"></i>
									    </el-input>
  								    </el-form-item>
  								    <el-form-item prop="password">
    								    <el-input type="password" v-model="ruleForm.password" placeholder='密码' autocomplete="off">
										    <i slot="prefix" class="pass_png"></i>
									    </el-input>
  								    </el-form-item>
									  <el-form-item prop="password_confirmation">
    								    <el-input type="password" v-model="ruleForm.password_confirmation" placeholder='确认密码' autocomplete="off">
										    <i slot="prefix" class="pass_png"></i>
									    </el-input>
  								    </el-form-item>
									<el-form-item prop="email">
    								    <el-input type="text" v-model="ruleForm.email" placeholder='邮箱' autocomplete="off">
										    <i slot="prefix" class="email_png"></i>
									    </el-input>
  								    </el-form-item>
  								    <el-form-item  class="code" prop="code">
									    <el-input class="yanzhen" placeholder='验证码' v-model="ruleForm.code">
										    <i slot="prefix" class="num_png"></i>
									    </el-input>
									    <!-- <canvas id="canvas" width="96px" height="44px" @click="handleCanvas"> </canvas> -->
										<div class="send_code" @click="sendCode">{{ getCodeText }}</div>
  								    </el-form-item>
									<el-form-item  class="upload" prop="apply_form_img">
									    <div
										class="avatar-uploader">
											<img @click="cilckUpLoad" v-if="imageUrl" :src="imageUrl" class="avatar">
											<i @click="cilckUpLoad" v-else class="el-icon-plus avatar-uploader-icon"></i>
											<input style="display: none;" ref="myInput" class="" type="file" @change="upLoad">
											<div class="up_text">(请上传申请表图片,单位申请上传加盖单位公章的申请表图片)</div>
										</div>
  								    </el-form-item>
								    <el-form-item>
									    <el-button @click="register('ruleForm')" class="free" type="primary">注册</el-button>
								    </el-form-item>
							    </el-form>
                            </div>
						</div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <foot></foot>
    </div>
</template>
<script>
import axios from '../http/axios';
    export default {
        data(){
			// 验证码校验
            var checkCode = (rule, value, callback) => {
        		if (!value) {
          			return callback(new Error('验证码不能为空'));
        		}
				callback()
        		// setTimeout(() => {
          		// 	if(value != this.true_code){
				// 		return callback(new Error('验证码错误'))
				// 	}
        		// });
      		}
			// 手机号校验
			var checkPhone = (rule,value,callback)=>{
                if (!value) {
          			return callback(new Error('请输入手机号'));
        		}
                if(!this.isPhoneNumber(value)){
                    return callback(new Error('请输入正确的手机号'))
                }
				callback()
            }
			// 确认密码校验
			var checkPassword = (rule,value,callback)=>{
                if (!value) {
          			return callback(new Error('请再次输入密码'));
        		}
                if(value != this.ruleForm.password){
                    return callback(new Error('两次密码不一致'))
                }
				callback()
            }
			// 邮箱校验
			var checkEmail = (rule,value,callback)=>{
                if (!value) {
          			return callback(new Error('请输入邮箱'));
        		}
                if(!this.validateEmail(value)){
                    return callback(new Error('请输入正确的邮箱格式'))
                }
				callback()
            }
            return{
				options:[ 
					{
						value:'个人会员'
					},
					{
						value:'单位会员'
					}
				],
                ruleForm: {
					type:'',
         			name: '',
					mobile:'',
          			password:'',
					password_confirmation:'',
					email:'',
					code:'',
					apply_form_img:null
       			},
                //校验
                rules: {
					mobile: [
            			{ validator: checkPhone, trigger: 'blur'  }
          			],
          			password: [
            			{ required:true , message:'请输入密码', trigger: 'blur' }
          			],
					apply_form_img:[
            			{ required:true , message:'请上传图片', trigger: 'blur' }
          			],
          			code: [
            			{ validator: checkCode, trigger: 'blur' }
          			],
					email: [
                        { validator: checkEmail, trigger: 'blur' }
          			],
					password_confirmation:[
                        { validator: checkPassword, trigger: 'blur' }
                    ]
        		},
                activeName:'notice',
                //验证码
				true_code:'',
				yanzheng_arr:[],
				getCodeText:'发送验证码',
				imageUrl:""
            }
        },
        watch:{
            activeName:{
                handler(newValue){
                    if(newValue == 'apply'){
                        this.username = ''
                        this.password = ''
                        this.yanzheng = ''
                    }
                }
            }
        },
        mounted(){
			this.getCodeText = '发送验证码'
			if(this.$route.query.activeName)[
				this.activeName = this.$route.query.activeName
			]	
        },
        methods:{
			cilckUpLoad(){
				this.$refs.myInput.click()
			},
			upLoad(e){
				let _this = this
				let file = e.target.files
				const reader = new FileReader()
				const reader1 = new FileReader()
				if(file[0].type == 'image/png'){
					reader.readAsDataURL(file[0])
					reader.onload = function(){
						_this.imageUrl = this.result
					}
					this.ruleForm.apply_form_img = file[0]
					reader1.readAsArrayBuffer(file[0])
					reader1.onload = function(){
						_this.ruleForm.apply_form_img = new Blob([this.result])
					}
				}else{
					this.$message.error("请确保文件为png图像类型");
					return false;
				}
			},
			isPhoneNumber(phoneNumber) {
                const regExp = /^1[3-9]\d{9}$/;
                return regExp.test(phoneNumber);
            },
            validateEmail(email) {
                const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                return emailRegex.test(email);
            },
			sendCode(){
				if(this.getCodeText != '发送验证码'){
					return
				}
				if(this.validateEmail(this.ruleForm.email)){
					axios.post('send/register/code',{email:this.ruleForm.email}).then(res=>{
						if(res.err_code == 200){
							this.getCodeText = '60'
							let time = setInterval(()=>{
								this.getCodeText--
								if(this.getCodeText == 0){
									clearInterval(time)
									this.getCodeText = '发送验证码'
								}
							},1000)
                            this.$message({
                                message:res.msg,
                                type: 'success'
                            })
                        }else{
                            this.$message.error(res.msg)
                        }
					}).catch(e=>{
						console.log(e);
					})
				}else{
					return
				}
			},
            register(formName){
				// console.log(this.$refs[formName]);
                this.$refs[formName].validate((valid) => {
          			if (valid) {
						this.ruleForm.type == '个人会员' ? this.ruleForm.type = 'personal' : this.ruleForm.type = 'unit'
						let formData = new FormData()
						for (const key in this.ruleForm) {
							formData.append(key,this.ruleForm[key])
						}
						axios.post('auth/register',formData).then(res=>{
							if(res.err_code == 200){
								this.$message({
									message:res.msg,
									type:'success'
								})
								this.$router.push('/')
							}else{
								this.$message.error(res.msg)
							}
							console.log(res);
						})
          			} else {
            			console.log('error submit!!');
            			return false;
          			}
        		});
            },           
        }
    }
</script>

<style scoped lang='less'>
.introduce{
    .intro_content{
        width: 1140px;
		min-width: 1140px;
        min-height: 517px;
        margin: 20px auto 40px;
        /deep/.el-tabs__active-bar,/deep/.el-tabs--left .el-tabs__nav-wrap.is-left::after{
            display: none;
        }
        /deep/.el-tabs--left .el-tabs__header.is-left{
            margin-right: 26px;
        }
        /deep/.el-tabs__item{
            width: 270px;
            height: 52px;
            color: #999999;
            font-size: 16px;
            line-height: 52px;
            padding: 0 27px;
            text-align: left;
            border: 1px solid #EBEBEB;
        }
        /deep/.el-tabs__item+.el-tabs__item{
            border-top: 0;
        }
        /deep/.el-tabs__item.is-active{
            background-color:#1D7FD7;
            color: #ffffff;
        }
        .tab_nav{
            font-size: 16px;
            padding-bottom: 8px;
            border-bottom: 2px solid #1D7FD7;
            span{
                color: #1D7FD7;
            }
        }
        .tab_text{
            margin-top: 24px;
            .title{
                font-size: 24px;
                color: #1D7FD7;
                line-height: 24px;
                margin-bottom: 19px;
				letter-spacing: 0;
            }
            >div{
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 22px;
				letter-spacing: 2px;
                >div{
                    margin-left: 22px;
                }
            }
            .submit{
				letter-spacing: 0;
                >div{
                    margin-left: 0;
                    display: flex;
                    align-items: center;
                }
                a{
                    font-size: 16px;
                    color: #333333;
                }
				a:hover{
					color: #FF1821;
				}
                i{
                    display: inline-block;
                    width: 18px;
                    height: 16px;
                    background-image: url('../assets/image/service/附件.png');
                    margin-right: 11px;
                }
            }
        }
        .login_con{
			padding: 0 20px;
            display: flex;
            justify-content: center;
			letter-spacing: 0 !important;
            >div{
				.el-select{
					width: 100%;
				}
				/deep/.el-form-item{
					margin-bottom: 20px;
				}
				// /deep/.el-form-item:last-child,/deep/.el-form-item:nth-child(4){
				// 	margin-bottom: 0;
				// }
				/deep/.el-input__inner{
					position: relative;
					border: 0;
                    border: 1px solid #EBEBEB;
					// width: 290px;
					height: 46px;
					border-radius: 4px;
					padding-left: 43px;
				}
				/deep/.el-button{
					width: 290px;
					height: 44px;
					color: #fff;
					font-size: 16px;
					background-color: #1D7FD7;
					border-radius: 4px;
				}
				/deep/.el-form-item__error{
					font-size: 14px;
					color: #FF1821;
				}
				.upload{
					/deep/.el-form-item__content{
						line-height: 24px;
					}
					.up_text{
						line-height: 26px;
					}
					/deep/.avatar-uploader{
						// border: 1px dashed #d9d9d9;
						// border-radius: 6px;
						cursor: pointer;
						position: relative;
						overflow: hidden;
						text-align: left;
					}
					.avatar-uploader:hover {
						border-color: #409EFF;
					}
					.avatar-uploader-icon {
						position: static;
						font-size: 28px;
						color: #8c939d;
						width: 124px;
						height: 160px;
						line-height: 160px;
						text-align: center;
						border-radius: 4px;
						border: 1px solid #EBEBEB;
					}
					.avatar {
						width: 124px;
						height: 160px;
						display: block;
					}
				}
				.code{
					/deep/.el-form-item__content{
						display: flex;
					}
					.yanzhen{
						width: 186px;
						margin-right: 8px;
					}
					.send_code{
                        cursor: pointer;
                        width: 96px;
                        line-height: 46px;
                        border-radius: 4px;
                        background-color: #1D7FD7;
                        color: #FFFFFF;
                        text-align: center;
                    }
				}
				i{
					position: absolute;
					top: 16px;
					left: 14px;
					width: 16px;
					height: 16px;
					background-repeat: no-repeat;
				}
				.vip_type{
					background-image: url('../assets/image/service/vip.png');
				}
				.unitname_png{
					background-image: url('../assets/image/service/unit.png');
				}
				.name_png{
					background-image: url('../assets/image/service/user.png');
				}
				.phone_png{
					background-image: url('../assets/image/service/phone.png');
				}
				.pass_png{
					background-image: url('../assets/image/homePage/pass.png');
				}
				.email_png{
					background-image: url('../assets/image/homePage/email.png');
				}
				.num_png{
					background-image: url('../assets/image/homePage/yanzheng.png');
				}
				#canvas{
					height: 44px;
					border-radius: 4px;
					border: 1px solid #C3D4E3;
					cursor: pointer;
				}
		    }
        }
    }
}
</style>